<div *ngIf="showModal && selectedNode && selectedLevel" class="imm-client-model-view-preview-modal">

    <div role="dialog" aria-label="Model Level Preview" class="imm-client-model-view-preview-modal-content" [style.backgroundColor]="selectedColor">
            <div tabindex="0" role="button" aria-label="Close Modal" (keydown.enter)="showModal = false" (click)="showModal = false" class="imm-client-model-view-preview-modal-content-close">
                <img src="assets/icons/close.svg" alt="">
            </div>

            <div class="imm-client-model-view-preview-modal-content-header">
                <span>{{depthStr}}</span>

                <h2>{{selectedNode.name}}</h2>
            </div>

            <div class="imm-client-model-view-preview-modal-content-body">

                <div [innerHTML]="selectedNode.content" class="body-content">

                </div>

                <h3 *ngIf="selectedNode.maturityLevels?.length">Maturity Levels</h3>

                <div class="body-maturity-levels">
                    <ng-container *ngIf="selectedNode.maturityLevels?.length">


                        <div class="body-content-maturity-levels">

                            <div *ngFor="let matLevel of selectedNode.maturityLevels; let i = index"
                                class="body-content-maturity-level">
                                <div class="body-content-maturity-level-header">
                                    <h2>{{i + 1}} {{matLevel.name}}</h2>
                                </div>

                                <div [innerHTML]="matLevel.content" class="body-content-maturity-level-body">

                                </div>
                            </div>

                        </div>

                    </ng-container>

                </div>





            </div>

            <div class="imm-client-model-view-preview-modal-content-explore">
                <a aria-label="Explore Further" [routerLink]="['view', selectedLevel!.code]" queryParamsHandling="merge" [fragment]="depthStr">Explore
                    Further <img src="assets/icons/right-chevron.svg" alt=""></a>
            </div>

    </div>

</div>



<ng-container *ngIf="_model">

    <imm-client-header>

        <div class="model-view-header-content">


            <div class="model-view-header-content-body">

                <h1 class="model-view-header-content-body-h1">{{_model.name}}</h1>
                <div [innerHTML]="_model.description" class="model-view-content-body">

                </div>

            </div>


        </div>


    </imm-client-header>

    <div class="imm-client-model-view-container">

        <div class="imm-client-model-view-filter-container">

            <ng-container *ngIf="_model.metadata | primaryFilter as filters">

                <div *ngFor="let filter of filters; let i = index" class="imm-client-model-view-filter">
                    <label id="model-filter">{{filter.filterLabel}}</label>

                    <p-dropdown aria-labelledby="model-filter" [(ngModel)]="selectedFilters[i]"  [showClear]="true"  optionValue="code"  (onChange)="onFilter()" placeholder="Select..." [autoDisplayFirst]="false" [style]="{'border': 'none', 'width': '100%', 'background': 'none'}" [options]="filter.collection.elements" >

                    </p-dropdown>

                </div>

            </ng-container>

        </div>


        <div class="imm-client-model-view-content">


            <div *ngIf="levels.length; else noResults" class="imm-client-model-view-levels">

                <div *ngFor="let level of levels; let i = index" class="imm-client-model-view-level">
                    <ng-container
                        *ngIf="_model.colorPalette.colors[nextColorIdx(i, _model.colorPalette.colors.length)] as color">

                        <div tabindex="0" (keydown.enter)="onNodeClick(level, color, '', level)" class="imm-client-model-view-level-container" [style.border]="'2px solid' + color">

                            <div #node class="imm-client-model-view-level-content" [style.color]="color">

                                <p>{{level.name}}</p>

                            </div>

                            <div role="button" aria-label="Show More About Level" (keydown.enter)="onNodeClick(level, color, '', level)" (click)="onNodeClick(level, color, '', level)" class="more-menu">
                                <svg width="18" height="4" viewBox="0 0 18 4" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7 2C7 3.1 7.9 4 9 4C10.1 4 11 3.1 11 2C11 0.9 10.1 0 9 0C7.9 0 7 0.9 7 2ZM4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2ZM14 2C14 3.1 14.9 4 16 4C17.1 4 18 3.1 18 2C18 0.9 17.1 0 16 0C14.9 0 14 0.9 14 2Z"
                                        [attr.fill]="color" />
                                </svg>
                            </div>

                        </div>


                        <div class="sub-levels" [ngClass]="{'sub-levels-column': isParentOfLeaf(level)}">
                            <ng-container
                                *ngTemplateOutlet="modelView; context:{ $implicit: {levels: level.subLevels, color: color, rootLevel: level} }">
                            </ng-container>
                        </div>

                    </ng-container>



                </div>

            </div>



        </div>

    </div>


    <ng-template #modelView let-level>
        <div *ngFor="let lvl of level.levels; let i = index" class="imm-client-model-view-sub-level">


            <div tabindex="0" (keydown.enter)="onNodeClick(lvl, level.color, lvl.depth, level.rootLevel)" class="imm-client-model-view-sub-level-container"
                [style.backgroundColor]="lvl?.subLevels?.length ? 'white' : level.color"
                [style.border]="'2px solid' + level.color">

                <ng-container>
                    <div #node class="imm-client-model-view-sub-level-content"
            
                        [style.border]="lvl?.subLevels?.length ? '' : '4px solid white'"
                        [style.color]="lvl?.subLevels?.length ?  level.color : 'white'">

                        <span *ngIf="!lvl?.subLevels?.length">{{lvl.depth}}</span>

                        <p>{{lvl.name}}</p>

                    </div>

                    <div role="button" aria-label="Show More About Level" (keydown.enter)="onNodeClick(lvl, level.color, lvl.depth, level.rootLevel)" (click)="onNodeClick(lvl, level.color, lvl.depth, level.rootLevel)" class="more-menu">
                        <svg width="18" height="4" viewBox="0 0 18 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7 2C7 3.1 7.9 4 9 4C10.1 4 11 3.1 11 2C11 0.9 10.1 0 9 0C7.9 0 7 0.9 7 2ZM4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2ZM14 2C14 3.1 14.9 4 16 4C17.1 4 18 3.1 18 2C18 0.9 17.1 0 16 0C14.9 0 14 0.9 14 2Z"
                                [attr.fill]="lvl?.subLevels?.length ?  level.color : 'white'" />
                        </svg>
                    </div>

                </ng-container>


            </div>



            <!-- {{level | json}} -->

            <div class="sub-levels" [ngClass]="{'sub-levels-column': isParentOfLeaf(lvl)}">
                <ng-container
                    *ngTemplateOutlet="modelView; context:{ $implicit: {levels: lvl.subLevels, color: level.color, rootLevel: level.rootLevel } }">
                </ng-container>
            </div>

            <!-- <div class="mat-levels">
            <ng-container *ngTemplateOutlet="modelView; context:{ $implicit: {levels: lvl.maturityLevels, color: level.color, depth: concatDepth(level.depth, i + 1) } }"></ng-container>
        </div> -->


        </div>


    </ng-template>

    <ng-template #noResults>

        <div class="no-results">

            <h1>No Results</h1>

        </div>

    </ng-template>


</ng-container>

<imm-client-resource-content-block></imm-client-resource-content-block>
<imm-client-contact-us-content-block></imm-client-contact-us-content-block>
