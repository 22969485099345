import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, map, of, skip, skipWhile, switchMap } from 'rxjs';
import { Collection, MaturityModel, MaturityModelLevelMetadata, MaturityModelMetadataCollection } from 'src/app/model/imm';
import { ImmClientDataService } from 'src/app/services/imm-client-data.service';
import { PreviewService } from 'src/app/services/preview.service';

@Injectable({
  providedIn: 'root'
})
export class ModelResolverService implements Resolve<{model: MaturityModel, systemCollections: Collection[]}> {

  constructor(private api: ImmClientDataService, private preview: PreviewService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): {model: MaturityModel, systemCollections: Collection[]} | Observable<{model: MaturityModel, systemCollections: Collection[]}> | Promise<{model: MaturityModel, systemCollections: Collection[]}> {
    let domain: string;
    if('domain' in route.queryParams){
      domain = route.queryParams['domain'];
    }else{
      domain = window.location.origin;
    }

    if('preview' in route.queryParams){
console.log('rec')
      return this.preview.modelPreview.pipe(skipWhile( v => !v ), map((v: any) => {
        console.log('bad');
        const colorPalette = v.colors.find((c: any) => c.id === v.model.colorPalette);
  
        const metadata = v.model.metadata.map((md: MaturityModelMetadataCollection) => ({...md, collection: v.metadata.find(((vmd: any) => vmd.id === md.collection))}))
  
        return {model: {...v.model, metadata, colorPalette} as any, systemCollections: v.metadata as any}
      }))

    }

    return this.api.getModel(domain);

  }
}
