<div class="privacy-policy-container">
  <div class="privacy-policy-content">
    <div class="privacy-policy-content-back">
      <a [routerLink]="'..'" aria-label="Go Back" queryParamsHandling="merge"><img src="assets/icons/left-chevron-blue.svg" alt=""> Back</a>
    </div>

    <div class="privacy-policy-content-text">
      <h1 class="privacy-policy-content-text-h1">Privacy Policy</h1>
      <p class="privacy-policy-content-text-p">As an advocate for improved data management and privacy practices, the
        Interoperability Maturity Model Website (“IMMW”) understands your desire to protect personal information. We
        intend to inform you how your personal information will be gathered, tracked, or used, and to give you choices.
      </p>
      <p class="privacy-policy-content-text-p">If you believe that choices are not offered on web pages where your
        personal information is obtained, please contact us <a href="https://www.cosn.org/about/contact-us/"
          target="_blank">here</a> to indicate your preferences.</p>
      <p class="privacy-policy-content-text-p">Last update: March 15, 2023</p>
      <h4 class="privacy-policy-content-text-h4">WHAT INFORMATION WE GATHER.</h4>
      <p class="privacy-policy-content-text-p">We may collect personally identifiable information when you visit or use
        our pages, including but not limited to contact information such as name and email address, as well as
        information around your content interests. We use this information for three general purposes: to improve on the
        content you see, to fulfill your requests for certain services and information, and to contact you.</p>
      <h4 class="privacy-policy-content-text-h4">WHAT INFORMATION WE AUTOMATICALLY LOG.</h4>
      <p class="privacy-policy-content-text-p">When you visit <a href="/"
          target="_blank">interopaccelerator.org</a>, we may collect certain information by automated means, including
        cookie information and web beacons. We may collect information such as your IP address, your browser type, and
        the page you requested.</p>
      <p class="privacy-policy-content-text-p">Cookies enable us to use third-party analytics services. These services
        allow us to understand how users engage with our content, so we can improve the quality and relevancy of our
        website. Additionally, our email service provider helps to facilitate communication with you about our services
        and offers.</p>
      <p class="privacy-policy-content-text-p">Use of these services is governed by additional terms. Your browser gives
        you options for how you restrict or disable certain cookies from the IMMW and third parties. You are also able
        to delete cookies. Please note, however, that without cookies you may not be able to use all of the features of
        our website.</p>
      <h4 class="privacy-policy-content-text-h4">LINKS TO OTHER WEBSITES.</h4>
      <p class="privacy-policy-content-text-p">Our website provides links to other websites for your information and
        convenience. If these sites are not controlled by the IMMW, please note that we are not responsible for the
        sites, including privacy policy and content.</p>
      <h4 class="privacy-policy-content-text-h4">INFORMATION SHARING AND DISCLOSURE.</h4>
      <p class="privacy-policy-content-text-p">We provide your information to the organizations and companies who are
        leading this initiative including <a href="https://www.cosn.org/" target="_blank">CoSN</a> and <a
          href="https://www.cgcs.org/" target="_blank">CGCS</a>, <a href="https://ccsso.org/" target="_blank">CCSSO</a>
        and <a href="http://www.aemcorp.com/" target="_blank">AEM Corporation</a>.</p>
      <p class="privacy-policy-content-text-p">We will not sell or rent your personally identifiable information. We
        will only send personally identifiable information about you to additional companies or people when:</p>
      <ul>
        <li class="privacy-policy-content-text-li">We have your consent to share the information;</li>
        <li class="privacy-policy-content-text-li">We need to share your information to provide the product or service
          you have requested;</li>
        <li class="privacy-policy-content-text-li">We respond to subpoenas, court orders or legal process; or</li>
        <li class="privacy-policy-content-text-li">We find that your actions on our web sites violate the above terms of
          service, or any of our usage guidelines for specific products or services.</li>
      </ul>
      <h4 class="privacy-policy-content-text-h4">UPDATES TO OUR PRIVACY POLICY.</h4>
      <p class="privacy-policy-content-text-p">This privacy policy may be updated from time to time. We will post the
        updated version to this page before it goes into effect and include the latest date of update at the top of the
        page.</p>

    </div>
  </div>
</div>
