import { Pipe, PipeTransform } from '@angular/core';
import { MaturityModelMetadataCollection } from '../model/imm';

@Pipe({
  name: 'primaryFilter',
  standalone: true
})
export class PrimaryFilterPipe implements PipeTransform {

  transform(value: MaturityModelMetadataCollection[]): MaturityModelMetadataCollection[] {
    return value.filter(v => v.isPrimary);
  }

}
