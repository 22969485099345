<div class="resource-content-block-container">
    <div class="resource-content-block-content">
        <div class="resource-content-block-content-header">
            <h1 class="resource-content-block-content-header-h1">Dive into the resource library.</h1>
            <p class="resource-content-block-content-header-p">
                Resources are being developed to help districts and states every step
                of the way towards building a more interoperable, modernized digital
                ecosystem. Once organizations determine “what” their next step is,
                these resources provide the "how."
            </p>
        </div>

        <a class="resource-content-block-content-a" aria-label="View Resource Library" routerLink="resources" queryParamsHandling="merge">Resource Library <img src="assets/icons/right-chevron-blue.svg" alt=""></a>
    </div>
</div>
