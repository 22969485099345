import { HttpClient } from '@angular/common/http';
import { HostListener, Injectable } from '@angular/core';
import { Response } from '../model/response';
import { Collection, MaturityModel } from '../model/imm';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, ReplaySubject, Subject, map, of, shareReplay, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImmClientDataService {


  private model$?: Observable<{model: MaturityModel, systemCollections: Collection[]}>;

  constructor(private http: HttpClient) { }


  public getModel(domain: string){
    if(!this.model$){
      this.model$ = this.http.get<Response<{model: MaturityModel, systemCollections: Collection[]}>>(`${environment.apiURL}/model`, {params: {domain}}).pipe(map(v => v.data), shareReplay(1))
    }

    return this.model$;
  }

  public reportKeyword(domain: string, keyword: string){
    return this.http.post(`${environment.apiURL}/reporting`, '', {params: {domain, keyword}})
  }

  public reportContactUs(domain: string, captcha: string, body: any){
    return this.http.post(`${environment.apiURL}/contact`, {domain, captcha, ...body})
  }
  

}
