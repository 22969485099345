import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject, map, switchMap } from 'rxjs';
import { Collection, MaturityModel } from '../model/imm';
import { ColorOption } from '../model/ColorOptions';

@Injectable({
  providedIn: 'root'
})
export class PreviewService {

  public modelPreview = new BehaviorSubject<{model: MaturityModel, colors: ColorOption[], metadata: Collection[]} | undefined>(undefined);

  public isPreview$ = this.modelPreview.asObservable().pipe(map((v) => !!v))

  constructor() { }
}
