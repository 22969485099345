import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';
import { ImmClientDataService } from '../services/imm-client-data.service';
import { PreviewService } from '../services/preview.service';
import { MaturityModel } from '../model/imm';

@Injectable({
  providedIn: 'root'
})
export class ContactGuard implements CanActivate {

  constructor(private api: ImmClientDataService, private router: Router, private preview: PreviewService){

  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    let model: MaturityModel;

    if('preview' in route.queryParams){
      const result = await firstValueFrom(this.preview.modelPreview)
      model = result!.model;
    }else{
      model = (await firstValueFrom(this.api.getModel(route.queryParams['domain'] ?? window.origin))).model;
    }


    if(model.publish?.type === 'url'){
     return this.redirect(model);
    }

    return true;
  }

  private redirect(model: MaturityModel){
    window.open(model.publish?.url, '_blank')
    this.router.navigate([''], {queryParamsHandling: 'merge'})
    return false;
  }
  
}
