import { Component, HostListener } from '@angular/core';
import { ImmClientDataService } from './services/imm-client-data.service';
import { MaturityModel } from './model/imm';
import { PreviewService } from './services/preview.service';
import { Partner } from './model/partner';
import { NavigationEnd, Router } from '@angular/router';
import { Meta } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'IMM-Client-App';

  public model?: MaturityModel;

  public partners: Partner[];

  private routerEventSub: Subscription;

  public skipLinkPath?: string;

  @HostListener('window:message', ['$event'])
  private onMessage($event: MessageEvent) {
    if (typeof $event.data === 'object' && 'model' in $event.data) {
      this.preview.modelPreview.next($event.data);
    }
  }

  constructor(private api: ImmClientDataService, private preview: PreviewService, private router: Router, private meta: Meta) {
    this.meta.addTag({
      name: 'og:description',
      content: 'The Interoperability Readiness Tool is designed to assist states and districts in thinking through common considerations for implementing an effective interoperability plan.'
    });

    this.routerEventSub = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {

        this.skipLinkPath = decodeURIComponent(this.router.createUrlTree([this.router.url], {fragment: 'main'}).toString());

        if(!e.url.includes("view")){
          window.scrollTo(0, 0);
        }
       
      }
    });

    this.partners = [
      { name: 'The Consortium for School Networking', alt: 'The Consortium for School Networking Logo', margins: {'top': '80px', 'left': '-15px'}, height: '125px', width: '200px', url: 'https://www.cosn.org/', logo: 'assets/images/cosn.png' },
      { name: 'The Council of the Great City Schools', alt: 'The Council of the Great City Schools', margins: {'top': '0px'}, height: '100px', width: '100%', url: 'https://www.cgcs.org/', logo: 'assets/images/cgcs.svg' },
      { name: 'Council of Chief State School Officers', alt: 'Council of Chief State School Officers Logo', margins: {'top': '75px'}, height: '125px', width: '200px', url: 'https://ccsso.org/', logo: 'assets/images/ccsso.png' }

    ];




  }
}
