import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ModelResolverService } from './model-view/resolver/model-resolver.service';
import { ContactGuard } from './guards/contact.guard';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';

const loadLevelView = () => import('./level-view/level-view.component').then(mod => mod.LevelViewComponent);
const loadModelView = () => import('./model-view/model-view.component').then(mod => mod.ModelViewComponent);
const loadResourcesView = () => import('./resources/resources.component').then(mod => mod.ResourcesComponent);
const loadContactUs = () => import('./contact-us/contact-us.component').then(mod => mod.ContactUsComponent);

const routes: Routes = [
  {path: '', loadComponent: loadModelView, resolve: {model: ModelResolverService}},
  {path: 'view/:level', loadComponent: loadLevelView, resolve: {model: ModelResolverService}},
  {path: 'resources', loadComponent: loadResourcesView, resolve: {model: ModelResolverService}},
  {path: 'contact-us', loadComponent: loadContactUs, canActivate: [ContactGuard], resolve: {model: ModelResolverService}},
  {path: 'privacy', component: PrivacyPolicyComponent},
  {path: 'terms-of-use', component: TermsOfServiceComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
