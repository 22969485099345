export function formatDepth(depth: number[]){
    let str = '';

    for(let i = 0; i < depth.length - 1; i++){
        str += `${depth[i]}.`
    }

    str += `${depth[depth.length - 1]}`.padStart(2, '0');

    return str;
}

export function concatDepth(depth: number[], idx: number){
    return [...depth, idx]
  }
