import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'imm-client-contact-us-content-block',
  standalone: true,
  templateUrl: './contact-us-content-block.component.html',
  imports: [CommonModule, RouterModule],
  styleUrls: ['./contact-us-content-block.component.scss']
})
export class ContactUsContentBlockComponent {

}
