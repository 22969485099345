import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Partner } from 'src/app/model/partner';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'imm-client-footer',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  @Input() partners: Partner[] = []

  public date = new Date().getFullYear()

  constructor(){
    
  }

  openAEM() {
    window.open('https://aemcorp.com', '_blank')
  }


}
