import { AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Level, MaturityLevel, MaturityModel, MaturityModelLevel, MaturityModelMetadataCollection } from '../model/imm';
import { ColorOption } from '../model/ColorOptions';
import { PrimaryFilterPipe } from '../pipes/primary-filter.pipe';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { formatDepth } from '../model/helpers';
import { HeaderComponent } from '../components/header/header.component';
import { PreviewService } from '../services/preview.service';
import { of } from 'rxjs';
import { DropdownModule } from 'primeng/dropdown';
import { ResourceContentBlockComponent } from '../resource-content-block/resource-content-block.component';
import { ContactUsContentBlockComponent } from '../contact-us-content-block/contact-us-content-block.component';


@Component({
  selector: 'imm-client-model-view',
  standalone: true,
  imports: [CommonModule, PrimaryFilterPipe, FormsModule, RouterModule, HeaderComponent, DropdownModule, ResourceContentBlockComponent, ContactUsContentBlockComponent],
  templateUrl: './model-view.component.html',
  styleUrls: ['./model-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModelViewComponent implements AfterViewInit {
  formatDepth = formatDepth

  public _model?: MaturityModel;

  public levels: MaturityModelLevel[] = [];

  public showModal = false;

  public selectedLevel?: MaturityModelLevel;
  public selectedNode?: MaturityModelLevel;
  public selectedColor?: string;
  public depthStr = '';

  public selectedFilter?: string;
  public selectedFilters : string[] = [];

  public maxNodeHeight: number | null = null;

  @ViewChildren('node') nodes? : QueryList<ElementRef>;

  constructor(private route: ActivatedRoute, private preview: PreviewService, private router: Router){

    this.route.data.subscribe(({model}) => {
      if(!model?.model){
        return;
      }
      this._model = model.model;
      this.levels = this.generateDepthIndexes(); // precompute the indexes

    })


    this.route.queryParams.subscribe((params) => {
      const filters = params['filter'];

      if(!filters){
        return
      }

      this.selectedFilters = Array.isArray(filters) ? filters : [filters]

      this.onFilter();

    })

  }



  private _generateDepthIndexes(levels: MaturityModelLevel[], depth: number[] = []){
    return levels.map((lvl, idx) => {

      const runningDepth = [...depth, idx + 1]

      lvl.depth = formatDepth(runningDepth)

      if(lvl.subLevels?.length){
        lvl.subLevels = this._generateDepthIndexes(lvl.subLevels, runningDepth)
      }

      return lvl;
    })
  }

  private generateDepthIndexes(){
    return this._generateDepthIndexes([...this._model!.model])
  }

  private adjustNodeSizes(){
    const max = this.nodes?.reduce((accum, element) => Math.max(accum, element.nativeElement.clientHeight), 0) ?? 128;

    this.maxNodeHeight = !this.maxNodeHeight ? max : Math.min(this.maxNodeHeight, max)

    this.nodes?.forEach((element) => {

      const native = element.nativeElement as HTMLDivElement;

      native.style.height = `${this.maxNodeHeight}px`;

    })
  }

  ngAfterViewInit(): void {

    this.nodes?.changes.subscribe(() => this.adjustNodeSizes())
  
    this.adjustNodeSizes();
  }
  

  public nextColorIdx(i: number, n: number){
    return Math.abs(i % n);
  }

  public onNodeClick(level: MaturityModelLevel, color: string, depthStr: string = '', rootLevel: MaturityModelLevel){
    this.selectedNode = level;
    this.selectedColor = color
    this.depthStr = depthStr;
    this.showModal = true;
    this.selectedLevel = rootLevel;
  }

  public _filter(levels: MaturityModelLevel[]){

    return levels.filter((rLvl) =>  {

      if(rLvl.subLevels?.length){
        this._filter(rLvl.subLevels)
        rLvl.subLevels = rLvl.subLevels.filter((lvl) => this._shouldShowSingle(lvl))
    
      }

      return this._shouldShowSingle(rLvl);

    })


  }

  public _shouldShowSingle(level: MaturityModelLevel | MaturityLevel): boolean{

    const exists = this.selectedFilters.every((f) => level.metadata.some((md) => md.selectedTags.find((st) => !f.length || st.code === f)));
    return exists || this._shouldShow((level as MaturityModelLevel).subLevels || (level as MaturityModelLevel).maturityLevels || [])
  }

  public _shouldShow(levels: MaturityModelLevel[] | MaturityLevel[]): boolean{
    return levels.some((lvl: MaturityModelLevel| MaturityLevel) => {

      const testExists = this.selectedFilters.every((f) => lvl.metadata.some((md) => md.selectedTags.find((st) => !f.length || st.code === f)))

      // const metadataExists = lvl.metadata.some((md) => md.selectedTags.find(st => this.selectedFilters.some((f) => f === st.code)))

      return testExists || this._shouldShow((lvl as MaturityModelLevel).subLevels || (lvl as MaturityModelLevel).maturityLevels || [])
    })
  }

  private _clone(obj: any){
    return JSON.parse(JSON.stringify(obj))
  }

  public onFilter(){

    // remove nulls
    this.selectedFilters = this.selectedFilters.filter((v) => !!v);

    if(this.selectedFilters.every((f) => f.length <= 0)){
      this.levels = this._clone(this._model!.model);
      this.router.navigate(['./'], {queryParams: {'filter': []}, queryParamsHandling: 'merge'})
      return;
    }

    this.levels = this._filter(this._clone(this._model!.model))

    this.router.navigate(['./'], {queryParams: {'filter': this.selectedFilters}, queryParamsHandling: 'merge'})
  }


  public concatDepth(depth: number[], idx: number){
    return [...depth, idx]
  }


  public isParentOfLeaf(level: MaturityModelLevel){
    return level.subLevels?.[0]?.maturityLevels?.length;
  }



}
