<footer class="footer-container">
  <div class="footer-content-wrapper">
    <div class="footer-content">
      <div class="footer-content-partners">
        <h1>Collaboration Partners</h1>

        <div class="footer-content-partners-list">
          <div *ngFor="let partner of partners" [style.width]="partner.width" [style.height]="partner.height">
            <a class="footer-content-partners-list-item" [href]="partner.url" target="_blank">
              <div [style.top]="partner?.margins?.top" class="partners-list-item-image">
                <img [style.width]="partner.width" [src]="partner.logo" [alt]="partner.alt" />
              </div>
  
              <!-- <div class="partners-list-item-content">
  
                              <div class=list-item-name>
                                  <h3>{{partner.name}}</h3>
                              </div>
                              <a [href]="partner.url" target="_blank">Visit Website</a>
                  </div> -->
            </a>
          </div>
        </div>
      </div>

      <!-- <hr> -->
      <div class="footer-content-aem-logo" (click)="openAEM()">
        <span>Powered by</span>
        <img src="assets/images/aem.png" alt="AEM Logo" />
      </div>
    </div>
  </div>

    <div class="footer-links-content">
        <div class="footer-links">

            <a aria-label="Home" routerLink="" queryParamsHandling="merge">Home</a>
            <a aria-label="Resource Library" routerLink="resources" queryParamsHandling="merge">Resource Library</a>
            <a aria-label="Contact Us"routerLink="contact-us" queryParamsHandling="merge">Contact Us</a>
            <a aria-label="Terms of Use"routerLink="terms-of-use" queryParamsHandling="merge">Terms of Use</a>
            <a aria-label="Privacy Policy" routerLink="privacy" queryParamsHandling="merge">Privacy Policy</a>

        </div>

        <span class="copyright">©{{date}}</span>
    </div>

</footer>

