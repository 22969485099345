<div class="terms-of-service-container">
  <div class="terms-of-service-content">
    <div class="terms-of-service-content-back">
      <a [routerLink]="'..'" aria-label="Go Back" queryParamsHandling="merge"><img src="assets/icons/left-chevron-blue.svg" alt=""> Back</a>
    </div>

    <div class="terms-of-service-content-text">
      <h1 class="terms-of-service-content-text-h1">Terms of Use</h1>
      <p class="terms-of-service-content-text-p">Access to and use of this site is subject to the following terms and
        conditions and all applicable laws. By accessing and using the Interoperability Maturity Model Website (“IMMW”)
        site, you accept the following terms and conditions, without limitation or qualification.</p>
      <p class="terms-of-service-content-text-p">Last update: March 15, 2023</p>
      <h4 class="terms-of-service-content-text-h4">RESTRICTIONS ON USE OF MATERIALS.</h4>
      <p class="terms-of-service-content-text-p">This site is operated by Consortium for School Networking (CoSN),
        Council of the Great City Schools (CGCS), Council of Chief State School Officers (“CCSSO”) and Applied
        Engineering Management Corporation (“AEM” or “AEM Corporation”), collectively referred to as “we," "us," or
        "our" herein.</p>
      <p class="terms-of-service-content-text-p">Content hosted on the site or produced and published by our team is
        made available under the <a href="https://creativecommons.org/licenses/by-sa/4.0/"
          target="_blank">Attribution-ShareAlike 4.0 International (CC BY-SA 4.0) license</a>. It may be shared and
        adapted for any purpose, even commercially, under the following terms:</p>
      <ol>
        <li class="terms-of-service-content-text-li">
          <u>Attribution</u>: You must give appropriate credit, provide a link to the license, and indicate if changes
          were made. You may do so in any reasonable manner, but not in any way that suggests the licensor endorses you
          or your use.
        </li>
        <li class="terms-of-service-content-text-li">
          <u>ShareAlike</u>: If you remix, transform, or build upon the material, you must distribute your
          contributions under the same license as the original.
        </li>
        <li class="terms-of-service-content-text-li">
          <u>Unrestricted</u>: You may not apply legal terms or technological measures that legally restrict others
          from doing anything the license permits.
        </li>
      </ol>
      <p class="terms-of-service-content-text-p">Unless where developed and published by our team, use of content linked
        in the Resources section is governed by the terms of a third-party organization. Modification of those materials
        and use of those materials is subject to review of the copyright guidance provided by those organizations.</p>
      <p class="terms-of-service-content-text-p">Except as otherwise indicated on this site and except for the
        trademarks, service marks and trade names of other organizations and companies that are displayed on this site,
        all trademarks, service marks and trade names are proprietary to the IMMW.</p>
      <h4 class="terms-of-service-content-text-h4">DISCLAIMER.</h4>
      <p class="terms-of-service-content-text-p">The materials in this site are provided "as is" and without warranties
        of any kind either expressed or implied. To the fullest extent permissible pursuant to applicable law, we
        disclaim all warranties, expressed or implied, including, but not limited to, implied warranties of
        merchantability and fitness for a particular purpose. We do not warrant that the functions contained in the
        materials will be uninterrupted or error-free, that defects will be corrected, or that the servers supporting
        this site are free of viruses or other harmful components. We do not warrant or make any representation
        regarding the use or the results of the use of the materials in this site in terms of their correctness,
        accuracy, reliability, or otherwise. You (and not us) assume the entire cost of all necessary servicing, repair,
        or correction. Applicable law may not allow the exclusion or implied warranties, so the above exclusion may not
        apply to you.</p>
      <h4 class="terms-of-service-content-text-h4">SITE MODIFICATION.</h4>
      <p class="terms-of-service-content-text-p">We reserve the right to make changes to our site, policies, and Terms
        of Use at any time without notice. We strongly encourage you to review our Terms of Use and Privacy Policy
        periodically to ensure that you are informed about any modification and changes.</p>
    </div>
  </div>
</div>
