import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'imm-client-resource-content-block',
  standalone: true,
  templateUrl: './resource-content-block.component.html',
  imports: [CommonModule, RouterModule],
  styleUrls: ['./resource-content-block.component.scss']
})
export class ResourceContentBlockComponent {

}
